<!--服务器列表-->
<template>
  <a-modal v-model="modalVisible" :title="modalTitle" :width="1280" :centered="true">
    <template slot="footer">
      <a-button @click="modalVisible=false">关闭</a-button>
    </template>
  <div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="服务器名称" prop="systemname">
              <a-input v-model="queryParams.systemname" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="服务器编号" prop="systemnum">
              <a-input v-model="queryParams.systemnum" placeholder="请输入"></a-input>
            </a-form-model-item>
            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">服务器列表</h3>
          <div class="table-btns">
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="systemid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="isonline" slot-scope="value">
            <a-tag :color="value=='1'?'green':'red'">{{value=='1'?'在线':'离线'}}</a-tag>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-button type="link" @click="showModal">详情</a-button>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
  </div>
    <a-modal v-model="sonModalVisible">
      <template slot="footer">
        <a-button @click="sonModalVisible=false">关闭</a-button>
      </template>
      <h3 style="font-size: 22px">平台自检异常数据</h3>
      <a-form-model ref="modalForm" :model="queryForms" :label-col="{span:7}" :wrapper-col="{span:14}">
        <a-form-model-item v-if="Date.parse(new Date())>Date.parse(time)" label="自检日志上报时间" prop="time">
          <a-input v-model="time" read-only :style="{'font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="queryForms.cpurateout=='1'" label="CPU平均使用率" prop="cpuavrrate">
          <a-input v-model="queryForms.cpuavrrate" read-only :style="{'background-color':'red','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="queryForms.cpurateout=='1'" label="CPU最大使用率" prop="cpumaxrate">
          <a-input v-model="queryForms.cpumaxrate" read-only :style="{'background-color':'red','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="queryForms.memoryrateout=='1'" label="内存总容量" prop="memorycapacity">
          <a-input v-model="queryForms.memorycapacity" read-only :style="{'font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="queryForms.memoryrateout=='1'" label="内存平均使用率" prop="memoryavrrate">
          <a-input v-model="queryForms.memoryavrrate" read-only :style="{'background-color':'red','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="queryForms.memoryrateout=='1'" label="内存最大使用率" prop="memorymaxrate">
          <a-input v-model="queryForms.memorymaxrate" read-only :style="{'background-color':'red','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="queryForms.diskrateout=='1'" label="磁盘总容量" prop="diskcapacity">
          <a-input v-model="queryForms.diskcapacity" read-only :style="{'font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="queryForms.diskrateout=='1'" label="磁盘平均使用率" prop="diskavrrate">
          <a-input v-model="queryForms.diskavrrate" read-only :style="{'background-color':'red','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="queryForms.diskrateout=='1'" label="磁盘最大使用率" prop="diskmaxrate">
          <a-input v-model="queryForms.diskmaxrate" read-only :style="{'background-color':'red','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="queryForms.iorateout=='1'" label="IO平均使用率" prop="ioavrrate">
          <a-input v-model="queryForms.ioavrrate" read-only :style="{'background-color':'red','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="queryForms.iorateout=='1'" label="IO最大使用率" prop="iomaxrate">
          <a-input v-model="queryForms.iomaxrate" read-only :style="{'background-color':'red','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="queryForms.upnetrateout=='1'||queryForms.downnetrateout=='1'" label="网络带宽" prop="netcapacity">
          <a-input v-model="queryForms.netcapacity" read-only :style="{'font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="queryForms.upnetrateout=='1'" label="网卡上行平均速率" prop="upnetavrrate">
          <a-input v-model="queryForms.upnetavrrate" read-only :style="{'background-color':'red','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="queryForms.upnetrateout=='1'" label="网卡上行最大速率" prop="upnetmaxrate">
          <a-input v-model="queryForms.upnetmaxrate" read-only :style="{'background-color':'red','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="queryForms.downnetrateout=='1'" label="网卡下行平均速率" prop="downnetavrrate">
          <a-input v-model="queryForms.downnetavrrate" read-only :style="{'background-color':'red','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="queryForms.downnetrateout=='1'" label="网卡下行最大速率" prop="downnetmaxrate">
          <a-input v-model="queryForms.downnetmaxrate" read-only :style="{'background-color':'red','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="queryForms.cputempout=='1'" label="CPU平均温度" prop="cpuavrtemp">
          <a-input v-model="queryForms.cpuavrtemp" read-only :style="{'background-color':'red','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="queryForms.cputempout=='1'" label="CPU最大温度" prop="cpumaxtemp">
          <a-input v-model="queryForms.cpumaxtemp" read-only :style="{'background-color':'red','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="queryForms.xinzhiinterface=='1'&&queryForms.servername=='ESPServer'" label="心知天气接口" prop="xinzhiinterface">
          <a-input v-model="queryForms.xinzhiinterface='0'?'正常':'异常'" read-only :style="{'background-color':'red','font-size':'20px'}"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </a-modal>

</template>
<script>
import pagination from '@/mixins/pagination'
import {getServerSystemListByCondition} from 'A/ai.js'
import {findSelfTestLogListByCondition} from "A/ai";
export default {
  mixins: [pagination],
  props:{
    visible: {
      default: false
    },
    showType:{
      default: '平台服务器列表'
    },
    devDetail: {
      default: ''
    },
  },
  data() {
    return {
      showAdvanced: false,
      sonModalVisible:false,
      queryParams: {
        systemname: '',
        systemnum: '',
      },
      time:'',
      queryForms:{
        selftestlogid:null,
        cpuavrrate:'',
        cpumaxrate:'',
        cpurateout:'',
        memorycapacity:'',
        memoryavrrate:'',
        memorymaxrate:'',
        memoryrateout:'',
        diskcapacity:'',
        diskavrrate:'',
        diskmaxrate:'',
        diskrateout:'',
        ioavrrate:'',
        iomaxrate:'',
        iorateout:'',
        netcapacity:'',
        upnetavrrate:'',
        upnetmaxrate:'',
        upnetrateout:'',
        downnetavrrate:'',
        downnetmaxrate:'',
        downnetrateout:'',
        cpuavrtemp:'',
        cpumaxtemp:'',
        cputempout:'',
        xinzhiinterface:'',
        selftestdate:'',
        servername: '',
      },
      tableColumns: [
        {
          title: '服务器名称',
          dataIndex: 'systemname',
          key: 'systemname',
          ellipsis: true,
        },
        {
          title: '服务器编号',
          dataIndex: 'systemnum',
          key: 'systemnum',
          ellipsis: true,
        },
        {
          title: '在线状态',
          dataIndex: 'isonline',
          key: 'isonline',
          ellipsis: true,
          scopedSlots: { customRender: 'isonline' }
        },
        {
          title: '描述',
          dataIndex: 'systemdecs',
          key: 'systemdecs',
          ellipsis: true,
        },
        {
          title: '操作',
          key: 'operation',
          width: 70,
          align: 'center',
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      username:'',

    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.getTableData(true);
      }
    },
    modalVisible(val) {
      if(!val) {
        this.$emit('update:visible', false);
      }
    }
  },
  methods: {
    modalTitle() {
      return this.showType
    },
    resetQueryParams() {
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        selftestdate: this.devDetail,
        status: "1",
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      if(params.systemnum==""&&params.systemname==""){
        getServerSystemListByCondition(params).then(res => {
            this.tableLoading = false;
            if (res && res.returncode == '0') {
              if (!(res.item[0] == undefined || res.item[0].length <= 0)) {
                this.tableData = res.item;
                this.counts = res.count;
              }else{
                this.$message.info("未找到相应日期的自检日志")
              }
            }
          }).catch(err => {
            this.tableLoading = false;
          })
        }
      getServerSystemListByCondition(params).then(res => {
          this.tableLoading = false;
          if (res && res.returncode == '0') {
            this.tableData = res.item;
            this.pagination.total = res.count;
          }
        }).catch(err => {
          this.tableLoading = false;
        })
    },
    showModal(value, record) {
      let params={
        selftestdate:this.devDetail,
        status:'1',
      }
      findSelfTestLogListByCondition(params).then(res=>{
        if(res&&res.returncode=='0'){
          if(res.item.length>0){
            this.queryForms=res.item[0]
            this.queryForms.upnetavrrate=this.queryForms.upnetavrrate+"Mbps"
            this.queryForms.upnetmaxrate=this.queryForms.upnetmaxrate+"Mbps"
            this.queryForms.downnetavrrate=this.queryForms.downnetavrrate+"Mbps"
            this.queryForms.downnetmaxrate=this.queryForms.downnetmaxrate+"Mbps"
            this.time=this.devDetail+' 23:52:50'
            this.sonModalVisible=true
          }else{
            this.$message.info("未找到相应日期的自检日志")
          }
        }
      })
    },
  }
}
</script>